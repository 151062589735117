import React from "react"
import ArticleContentBody from "../../components/pages/ArticleDetails/ArticleContentBody"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const SignUpWithSelfCreate = () => {
  return (
    <Layout>
      <Seo
        title="Account Registration Guide"
        description={`Follow our comprehensive account registration guide to easily set up your Synkli account. Get started quickly and efficiently with our step-by-step instructions.`}
      />

      {/* This slug is matched with articles in Content */}
      <ArticleContentBody slug={`account-registration-guide`} />
    </Layout>
  )
}

export default SignUpWithSelfCreate
